import React from 'react';
import {
	Divider__container,
	Divider__inner,
	Divider__line,
} from './index.styled';

const Divider = ({
	backgroundColor = 'white',
	desktopColor,
	anchorId,
}) => (
	<Divider__container backgroundColor={backgroundColor} id={anchorId || ''}>
		<Divider__inner>
			<Divider__line desktopColor={desktopColor} />
		</Divider__inner>
	</Divider__container>
);

export default Divider;
